import React from "react";
import { UserContext } from "../../App";

const withContext = (Component) => {
    return (props) => {
        return (
            <UserContext.Consumer>
                {(context) => <Component {...props} context={context} />}
            </UserContext.Consumer>
        );
    };
};

export default withContext;
