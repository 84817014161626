import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from 'App'
export default class RightChecker extends Component {
    static propTypes = {
        right: PropTypes.string.isRequired,
        showWithoutRight: PropTypes.string
    }

    render() {
        return (
            <UserContext.Consumer >
                {value => {
                    return value.rights !== null && value.rights !== undefined &&
                        ((value.rights.filter(e => e.kRight === this.props.right).length > 0 && !this.props.showWithoutRight)
                            || (this.props.showWithoutRight && value.rights.filter(e => e.kRight === this.props.right).length === 0)) &&
                        this.props.children
                }
                }
            </UserContext.Consumer>
        )
    }

}
