import { Button } from "primereact/button";
import React, { Component } from "react";
import heroshot from "./syntro-cloud.svg";
interface Props {}
interface State {}

export default class Hero extends Component<Props, State> {
  state = {};

  render() {
    return (
      <div className="grid grid-nogutter surface-0 text-800">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
          <section>
            <span className="block text-6xl font-bold mb-1">
              Erstellen Sie die Server
            </span>
            <div className="text-6xl text-primary font-bold mb-3">
              die Sie benötigen
            </div>
            <p className="mt-0 mb-4 text-700 line-height-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <Button
              label="Server erstellen"
              type="button"
              className="mr-3 p-button-raised"
            />
            <Button
              label="Zu den Servern"
              type="button"
              className="p-button-outlined"
            />
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden">
          <img
            src={heroshot}
            alt="hero-1"
            className="md:ml-auto block md:h-full"
            style={{
              clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)",
            }}
          />
        </div>
      </div>
    );
  }
}
