import React, { Component } from "react";
import q from "./syntro_loading.svg";

interface Props {
    size: string;
}

export default class Loading extends Component<Props> {
    render() {
        return (
            <div
                style={{
                    height: this.props.size,
                    width: this.props.size,
                }}
            >
                <object type="image/svg+xml" data={q}>
                    svg-animation
                </object>
            </div>
        );
    }
}
