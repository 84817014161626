import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { Component } from "react";
import { domainGetWhoIs } from "../../logik/apiCalls";
import withContext from "../shared/withContext";
import { Panel } from "primereact/panel";
import { InputTextarea } from "primereact/inputtextarea";

interface Props {
    context: any;
    onVerfuegbar: () => void;
    onChange: (domain: string) => void;
}
interface State {
    domain: string;
    timeoutId: NodeJS.Timeout | null;
    loading: boolean;
    status: string;
    details: string;
}

class DomainChecker extends Component<Props, State> {
    state = {
        domain: "",
        timeoutId: null,
        loading: false,
        status: "",
        details: "",
    };
    constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.checkDomainAvailability = this.checkDomainAvailability.bind(this);
        this.getMessages = this.getMessages.bind(this);
        this.getDomain = this.getDomain.bind(this);
    }

    render() {
        return (
            <div className="bg-white">
                <InputText
                    placeholder="Domain eingeben"
                    value={this.state.domain}
                    onChange={this.onChange}
                />
                {this.state.loading && <ProgressSpinner />}
                {this.state.status !== "" ? (
                    <>
                        {this.getMessages()}
                        <Panel header="Details" toggleable collapsed={true}>
                            <div className="fluid">
                                <div className="field">
                                    <InputTextarea
                                        className="w-100"
                                        value={this.state.details}
                                        disabled
                                    />
                                </div>
                            </div>
                        </Panel>
                    </>
                ) : (
                    <span>Jetzt schnell deine Domain Reservieren!</span>
                )}
            </div>
        );
    }

    getMessages() {
        let serverity: string;
        let text: string;
        if (this.state.status.includes("invalid")) {
            serverity = "error";
            text = "Die angegebene Domain ist Invalide";
        } else if (this.state.status.includes("connect")) {
            serverity = "warn";
            text = "Die Domain ist bereits Vergeben";
        } else {
            serverity = "success";
            text = "Herzlichen Glückwunsch  die Domain ist frei";
            return (
                <>
                    <Message severity={serverity} text={text} />
                    {this.props.onVerfuegbar !== null}
                    <br />
                    <Button
                        onClick={this.props.onVerfuegbar}
                        label="Jetzt Bestellen"
                    />
                </>
            );
        }
        return <Message severity={serverity} text={text} />;
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (this.state.timeoutId !== null) {
            clearTimeout(this.state.timeoutId);
        }
        var timeout: NodeJS.Timeout | null;
        if (this.state.domain.length > 2) {
            timeout = setTimeout(this.checkDomainAvailability, 1000);
        } else {
            timeout = null;
        }
        if (this.props.onChange !== null) {
            this.props.onChange(this.state.domain);
        }
        this.setState({
            domain: e.target.value,
            loading: this.state.domain.length > 2,
            timeoutId: timeout,
            details: "",
            status: "",
        });
    }

    async checkDomainAvailability() {
        var response: { status: string; details: string } =
            await domainGetWhoIs(this.state.domain);
        console.log(response);
        this.setState({
            loading: false,
            details: response.details,
            status: response.status,
        });
    }

    getDomain() {
        return this.state.domain;
    }
}

export default withContext(DomainChecker);
